/** @jsx jsx */
import { jsx } from 'theme-ui';

const ContactForm = () => {
  return (
    <section>
      <div
        className="text-content"
        sx={{
          mt: '3rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>
          <h3
            sx={{
              variant: 'text.xl',

              color: '#666666',
              mb: '1rem',
            }}
          >
            Got questions? We’re here to help.
          </h3>
          <p
            sx={{
              variant: 'text.normal',
            }}
          >
            Do you have a success story? Maybe some questions or concerns. We would love to hear
            from you. Please leave some information and your question, comment, or concern, and we
            will contact you as soon as we can! Thank you for supporting the National Embryo
            Donation Center.
          </p>
        </div>
      </div>
      <div sx={{ display: 'flex', justifyContent: 'center', px: '4rem', mb: '1rem' }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://us8.list-manage.com/contact-form?u=3a51f57f7bb874554f152b438&form_id=5478af5e12bdf9ba33556d74d683aa6f"
          sx={{
            display: 'inline-block',
            fontSize: '1rem',
            color: ' #FFFFFF',
            p: '1rem 1.5rem',
            background: ' #F7A496',
            border: '1px solid #F7A496',
            borderRadius: '8px',
            minWidth: '200px',
            textAlign: 'center',
            '&:hover': {
              opacity: '0.9',
            },
          }}
        >
          Click here to Contact Us
        </a>
      </div>
    </section>
  );
};

export default ContactForm;
