import * as React from 'react';
import Address from '../components/Contact/Address';
import ContactForm from '../components/Contact/ContactForm';
import Layout from '../components/Layout/index';
import { Seo } from '../components/Seo';

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        title="Contact the National Embryo Donation Center Today! | National Embryo Donation Center"
        description="The NEDC can help you navigate the process of in vitro fertilization, embryo donation and much more. Contact us today to get the info and help you need"
      />
      <div>
        {/* <Header title="Contact Us" /> */}
        {/* <Header
          title="Contact Us"
        /> */}
        <Address />
        <ContactForm />
      </div>
    </Layout>
  );
};

export default ContactPage;
